.input-view {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 25px;
}

.input-view input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
    font-size: 22px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 0;
    color: #fff;
    outline: none;
    text-align: center;
}

.input-view div + input {
    border-right: none;
}

::-webkit-input-placeholder { color: lightgrey; text-align: left; font-style: italic; font-size: 18px; }
::-moz-placeholder          { color: lightgrey; text-align: left; font-style: italic; font-size: 18px; } 
:-moz-placeholder           { color: lightgrey; text-align: left; font-style: italic; font-size: 18px; }
:-ms-input-placeholder      { color: lightgrey; text-align: left; font-style: italic; font-size: 18px; }