.App {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;
    color: #fff;
}

.App h2 {
    text-align: center;
}