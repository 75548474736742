.copy {
    position: relative;
}

.copy-body {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 47px;
    height: 47px;
    border: 2px solid #fff;
    cursor: pointer;
}

.copy-tooltip {
    cursor: default;
    position: absolute;
    left: -23.5px;
    bottom: -36px;
    padding: 5px;
    background: #000;
    border-radius: 20px;
    font-size: 12px;
}

.copy-tooltip:after {
    content: "";
    position: absolute;
    top: -16px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom-color: #000;
}