.filter div {
    margin-bottom: 15px;
}

.filter-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

label {
    margin: 0;
}

input[type=range] {
    max-width: 200px;
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background: transparent;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #2497E3;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #2497E3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
    background: #2497E3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #2497E3;
}

input[type=range]:focus::-ms-fill-upper {
    background: #2497E3;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #2497E3;
    background: #2497E3;
}

.custom-control-label::before,
.custom-control-label::after {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
}

.custom-control-label {
    padding-left: 10px;
    font-size: 18px;
    cursor: pointer;
}

.custom-btn {
    display: block;
    margin: 20px auto;
    padding-top: .575rem;
    padding-bottom: .575rem;
    outline: none;
    background: #2497e3;
    border-color: #2497e3;
}

.custom-btn:hover {
    background: rgba(36, 151, 227, .8);
    border-color: rgba(36, 151, 227, .8);
}

.custom-btn:not(:disabled):not(.disabled):active {
    background: rgba(36, 151, 227, .8);
    border-color: rgba(36, 151, 227, .8);
}